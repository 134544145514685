import React from "react";
import MainNavBar from "./MainNavBar";
import { graphql, StaticQuery } from "gatsby";
import styles from "../styles/amenities.css";
import Footer from "./Footer";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export const query = graphql`
  query amenities {
    ourServicesGatsby: file(relativePath: { eq: "updated-our-services.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dataJson {
      amen_title_deluxe
      amen_paragraph
      amen_small_title
    }
  }
`;

function Amenities({ data }) {
  const ourServicesGatsby = getImage(data.ourServicesGatsby);

  const x = 10;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#effafc",
    boxShadow: "none",
  }));

  const ItemTwo = styled(Paper)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: " auto auto auto auto",
    backgroundColor: "#effafc",
    boxShadow: "none",
  }));

  const ItemThree = styled(Paper)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto  auto auto auto auto auto",
    backgroundColor: "#effafc",
    boxShadow: "none",
  }));

  return (
    <div>
      <div>
        <MainNavBar />
      </div>
      <div className="about-us-cnt">
        <h1 className="about-us-title">{data.dataJson.amen_title_deluxe}</h1>
      </div>

      <section className="main-box">
        <div className="main-about-us-cnt">
          <div className="img-card-amen">
            {/* <div className="testing">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Item>
                      <p className="txt-amen">What's there to do?</p>
                    </Item>
                  </Grid>
                  <Grid item xs={7}>
                    <ItemTwo>
                      {[...Array(4)].map((e, i) => (
                        <div className="amen-pics" key={i}>
                          <StaticImage
                            className="circle-img-amen"
                            placeholder="blurred"
                            src="../images/slide-1.png"
                            alt="portrait"
                          />
                        </div>
                      ))}
                    </ItemTwo>
                  </Grid>
                  <Grid item xs={12}>
                    <ItemThree>
                      {" "}
                      <div className="amen-pics ">
                        <StaticImage
                          className="circle-img-amen"
                          placeholder="blurred"
                          src="../images/slide-1.png"
                          alt="portrait"
                        />
                      </div>
                      <div className="amen-pics ">
                        <StaticImage
                          className="circle-img-amen"
                          placeholder="blurred"
                          src="../images/slide-1.png"
                          alt="portrait"
                        />
                      </div>
                      <div className="amen-pics ">
                        <StaticImage
                          className="circle-img-amen"
                          placeholder="blurred"
                          src="../images/slide-1.png"
                          alt="portrait"
                        />
                      </div>
                      <div className="amen-pics ">
                        <StaticImage
                          className="circle-img-amen"
                          placeholder="blurred"
                          src="../images/slide-1.png"
                          alt="portrait"
                        />
                      </div>{" "}
                      <div className="amen-pics ">
                        <StaticImage
                          className="circle-img-amen"
                          placeholder="blurred"
                          src="../images/slide-1.png"
                          alt="portrait"
                        />
                      </div>{" "}
                      <div className="amen-pics ">
                        <StaticImage
                          className="circle-img-amen"
                          placeholder="blurred"
                          src="../images/slide-1.png"
                          alt="portrait"
                        />
                      </div>
                    </ItemThree>
                  </Grid>
                </Grid>
              </Box>
            </div> */}

            <div className="team-pic-cnt-amen">
              <p className="txt-amen">What's there to do?</p>
              {[...Array(6)].map((e, i) => (
                <div className="amen-pics " key={i}>
                  <StaticImage
                    className="circle-img-amen"
                    placeholder="blurred"
                    src="../images/slide-1.png"
                    alt="portrait"
                  />
                </div>
              ))}

              <div className="amen-pics ">
                <StaticImage
                  className="circle-img-amen"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>
              <div className="amen-pics ">
                <StaticImage
                  className="circle-img-amen"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>
              <div className="amen-pics ">
                <StaticImage
                  className="circle-img-amen"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>
            </div>
            {/* <div class="wrapper">
              <div class="header">
                <div className="pic-cnt-amen">
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                </div>
              </div>
              <div class="sidebar">
                <p className="txt-amen">{data.dataJson.amen_small_title}</p>
              </div>
              <div class="content">
                <div className="pic-cnt-amen">
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>

                  <div className="pic-cnt">
                    <StaticImage
                      className="circle-img-amen"
                      placeholder="blurred"
                      src="../images/slide-1.png"
                      alt="portrait"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="serv-left-side-para">
              <p>{data.dataJson.amen_paragraph}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="image-about-us-box">
        <div className="image-about-us-cnt">
          <div className="image-card">
            <GatsbyImage
              className="image-about-us-newPage"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Amenities;
